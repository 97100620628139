var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "id": "ReapPayAuth",
      "name": "ReapPayAuth",
      "action": _vm.gateway,
      "method": "post"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mid,
      expression: "mid"
    }],
    attrs: {
      "type": "hidden",
      "name": "pgMerchNo"
    },
    domProps: {
      "value": _vm.mid
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.mid = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.storeName,
      expression: "storeName"
    }],
    attrs: {
      "type": "hidden",
      "name": "pgMerchName"
    },
    domProps: {
      "value": _vm.storeName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.storeName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.storeURL,
      expression: "storeURL"
    }],
    attrs: {
      "type": "hidden",
      "name": "storeUrl"
    },
    domProps: {
      "value": _vm.storeURL
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.storeURL = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.biznessNo,
      expression: "biznessNo"
    }],
    attrs: {
      "type": "hidden",
      "name": "storeBizNo"
    },
    domProps: {
      "value": _vm.biznessNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.biznessNo = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderNo,
      expression: "orderNo"
    }],
    attrs: {
      "type": "hidden",
      "name": "orderSeq"
    },
    domProps: {
      "value": _vm.orderNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderNo = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.type,
      expression: "type"
    }],
    attrs: {
      "type": "hidden",
      "name": "productType"
    },
    domProps: {
      "value": _vm.type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.type = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderName,
      expression: "orderName"
    }],
    attrs: {
      "type": "hidden",
      "name": "goodsName"
    },
    domProps: {
      "value": _vm.orderName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentAmount,
      expression: "paymentAmount"
    }],
    attrs: {
      "type": "hidden",
      "name": "totAmt"
    },
    domProps: {
      "value": _vm.paymentAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentAmount = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vatAmount,
      expression: "vatAmount"
    }],
    attrs: {
      "type": "hidden",
      "name": "vatAmt"
    },
    domProps: {
      "value": _vm.vatAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.vatAmount = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currencyType,
      expression: "currencyType"
    }],
    attrs: {
      "type": "hidden",
      "name": "currencyType"
    },
    domProps: {
      "value": _vm.currencyType
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.currencyType = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "name": "custName"
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymethod,
      expression: "paymethod"
    }],
    attrs: {
      "type": "hidden",
      "name": "payMethod"
    },
    domProps: {
      "value": _vm.paymethod
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymethod = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.responseURL,
      expression: "responseURL"
    }],
    attrs: {
      "type": "hidden",
      "name": "returnUrl"
    },
    domProps: {
      "value": _vm.responseURL
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.responseURL = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.redirectURL,
      expression: "redirectURL"
    }],
    attrs: {
      "type": "hidden",
      "name": "successUrl"
    },
    domProps: {
      "value": _vm.redirectURL
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.redirectURL = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.redirectURL,
      expression: "redirectURL"
    }],
    attrs: {
      "type": "hidden",
      "name": "failureUrl"
    },
    domProps: {
      "value": _vm.redirectURL
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.redirectURL = $event.target.value;
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }