<template>
<form id="ReapPayAuth" ref="form" name="ReapPayAuth" :action="gateway" method="post">
    <input type="hidden" name="pgMerchNo" v-model="mid">
    <input type="hidden" name="pgMerchName" v-model="storeName">
    <input type="hidden" name="storeUrl" v-model="storeURL">
    <input type="hidden" name="storeBizNo" v-model="biznessNo">
    <input type="hidden" name="orderSeq" v-model="orderNo">
    <input type="hidden" name="productType" v-model="type">
    <input type="hidden" name="goodsName" v-model="orderName"> 
    <input type="hidden" name="totAmt" v-model="paymentAmount">
    <input type="hidden" name="vatAmt" v-model="vatAmount">
    <input type="hidden" name="currencyType" v-model="currencyType">
    <input type="hidden" name="custName" v-model="buyerName">
    <input type="hidden" name="payMethod" v-model="paymethod">
    <input type="hidden" name="returnUrl" v-model="responseURL">
    <input type="hidden" name="successUrl" v-model="redirectURL">
    <input type="hidden" name="failureUrl" v-model="redirectURL">
</form>
</template>

<script>
export default {
    data() {
        return {
            gateway: null,
            mid: null,
            storeName: null,
            biznessNo: null,
            orderNo: this.$route.query.orderNo,
            orderName: this.$route.query.orderName,
            type: "D",  //상품구분 R: 실물, D: 디지털
            paymentAmount: this.$route.query.paymentAmount || 0,        // 결제금액
            vatAmount: "0",        // 부가세액
            currencyType: "KRW",   //원화 고정값
            buyerName: this.$route.query.buyerName,      // 주문자명
            paymethod: "CARD", //결제타입 CARD 하나뿐
        }
    },
    mounted() {
        console.log({ query: this.$route.query });
        window.reappayComplete = (payload) => {
            window?.parent?.reappayComplete(payload);
            window?.opener?.reappayComplete(payload);
        };

        this.auth();
    },
    beforeDestroy() {
        window.reappayComplete = undefined;
        window.auth = undefined;
    },
    methods: {
        async auth() {
            var { gateway, mid, storeName, biznessNo } = await this.$http.get(`/api/plugins/reap/reappay/config`).then(res => res.data);

            this.gateway = gateway;
            this.mid = mid;
            this.storeName = storeName;
            this.storeName = "플러스핀";
            this.biznessNo = biznessNo;

            this.$nextTick(() => {
                var form = this.$refs.form;

                console.log({ form });

                if( navigator.userAgent.indexOf("Android") > - 1 || navigator.userAgent.indexOf("iPhone") > - 1 )
                {
                    // 모바일 환경일때 처리
                }
                else{
                    // PC 환경일때 처리
                    var width = 500;
                    var height = 500;
                    var leftpos = screen.width / 2 - (width / 2);
                    var toppos = screen.height / 2 - (height / 2);
                    var feature = `width=${width}, height=${height}, toolbar=no, status=no, statusbar=no, menubar=no, scrollbars=no, resizable=no, left=${leftpos}, top=${toppos}`;

                    window.open('about:blank', "ReapPayPopup", feature);

                    form.target = "ReapPayPopup";
                }
                form.submit();
            });
        },
    },
    computed: {
        storeURL(){
            let protocol = window.location.protocol;
            protocol += (-1 < protocol.indexOf(':') ? "//" : "://" );
            return `${protocol}${window.location.host}`;
        },
        // 노티
        responseURL(){
            return `${this.storeURL}/api/plugins/reap/reappay/response`;
        },
        redirectURL(){
            return `${this.storeURL}/api/plugins/reap/reappay/redirect`;
        }
    }
};
</script>
